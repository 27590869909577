<template>
  <v-btn
    v-if="user"
    @click="favorite()"
    icon
  >
    <v-icon
      :color="$store.getters['favorite/isFavorite'](user.userid) ? 'red' : 'grey'"
    >mdi-heart</v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    user: Object,
    disable: Boolean,
  },

  data() {
    return {
      favorited: false,
    }
  },

  methods: {
    ...mapActions('user', [
        'toggleFavorite',
    ]),

    favorite(){
      this.toggleFavorite(this.user.userid)
    },

  },
}
</script>